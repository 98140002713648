<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        :more-info-in-dialog="false"
      >
        <template slot="extraSubtitle">
          <extra-subtitle
            :principal="formattedItem.principal"
          />
        </template>
      </b10-view-summary>
      <b10-fab-button
        v-if="puedeEditar"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './RepresentanteViewData'
import ExtraSubtitle from './components/ExtraSubtitle'

export default {
  components: {
    ExtraSubtitle
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    tipo: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      moreInfoRows: [
        { name: 'email', label: 'Correo electrónico' },
        { name: 'domicilio', label: 'Domicilio social' },
        { name: 'dni', label: 'NIF' },
        { name: 'cargo', label: 'Cargo' },
        { name: 'nombre_notario', label: 'Nombre del notario' },
        { name: 'localidad_notario', label: 'Localidad del notario' },
        { name: 'protocolo_notario', label: 'Protocolo del notario' },
        { name: 'fescritura', label: 'Fecha de escritura', filter: this.$options.filters.shortDate },
        { name: 'registro_mercantil', label: 'Registro mercantil' },
        { name: 'idioma', label: 'Idioma' },

      ],
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        principal: {
          title: 'Establecer como principal',
          visible: true,
          icon: 'check',
        },
      },
      puedeEditar: false,
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idrepresentante
        item.title = item.nombre
        item.subtitle = item.cargo
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      // busco el permiso aquí para no tener que buscarlo dos veces (para el botón de editar y la opción de marcar como principal)
      if (this.tipo === 'cliente_potencial') {
        this.puedeEditar = this.hasEditPerm(this.permissions.clientePotencialRepresentante.id)
      } else if (this.tipo === 'cliente') {
        this.puedeEditar = this.hasEditPerm(this.permissions.clienteRepresentante.id)
      }
      this.toolbarOptions.principal.visible = this.puedeEditar && !this.item.dataset.principal
      this.title = this.item.dataset.nombre
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectRepresentante(
          this, this.routeParams.idrepresentante
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.principal) {
        this.setPrincipal()
      }
    },
    async setPrincipal () {
      const res = await this.$alert.showConfirm(`¿Deseas establecer a "${this.item.dataset.nombre}" como representante principal`)
      if (res) {
        await Data.setRepresentantePrincipal(
          this,
          this.item.dataset.idrepresentante
        )
        this.$alert.showSnackbarSuccess(`Se ha asignado a ${this.item.dataset.nombre} como representante principal`)
        this.loadPage()
      }
    },
    async clickEdit () {
      if (this.tipo === 'cliente_potencial') {
        this.$appRouter.push({
          name: 'representantes__clientes-potenciales-representante-edit',
          params: {
            idrepresentante: this.routeParams.idrepresentante,
            idcliente_potencial: this.routeParams.idcliente_potencial,
          },
        })
      } else if (this.tipo === 'cliente') {
        this.$appRouter.push({
          name: 'representantes__clientes-representante-edit',
          params: {
            idrepresentante: this.routeParams.idrepresentante,
            idcliente: this.routeParams.idcliente,
          },
        })
      }
    },
  },
}
</script>
