import { APIFilter } from '@/utils/api'

export default {
  async selectRepresentante (Vue, idrepresentante) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idrepresentante', idrepresentante)
    const resp = await Vue.$api.call('representante.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async setRepresentantePrincipal (Vue, idrepresentante) {
    await Vue.$api.call(
      'representante.setRepresentantePrincipal',
      {
        idrepresentante: idrepresentante,
      },
    )
  },
}
